// parse cookie based on key value pair
import {
  PencilIcon,
  AcademicCapIcon,
  BanknotesIcon,
  CheckBadgeIcon,
  ClockIcon,
  ReceiptRefundIcon,
  Bars3Icon,
  BellIcon,
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  ChevronRightIcon,
  ChatBubbleBottomCenterIcon,
  CubeTransparentIcon,
  BookOpenIcon
} from '@heroicons/react/24/outline'
export const statusEnum = {
  INVALID: 0,
  CREATED: 1, 
  FREE_AIRDROP: 2,
  ON_SALE: 3,
  CLAIMED: 4,
  SOLD: 5,
  MINTED: 6
}

export function setCookie(cname, cvalue) {
  document.cookie = cname + "=" + cvalue + ";"  + ";path=/";
}
export function returnInitNavigation(intl)
{
  
  const navigationArray = [
    { name: intl.formatMessage({id: 'training_info/DASHBOARD'}), href: '/portal', icon: HomeIcon, current: true },
    { name: intl.formatMessage({id: 'training_info/ADMIN'}), href: '/adminpanel', icon: UsersIcon, current: true, type:'ACCESS_MODIFY_USER' },
    { name: intl.formatMessage({id: 'training_info/MANAGESCHOOLMEMBERS'}), href: '/schoolmembermanager', icon: UsersIcon, current: false, type:'ACCESS_MANAGESCHOOLMEMBERS'},
    { name: intl.formatMessage({id: 'training_info/MANAGECLASSES'}), href: '/schoolclassviewer', icon: UsersIcon, current: false, type:'ACCESS_MANAGECLASSES'},
    { name: intl.formatMessage({id: 'training_info/SCHOOLMEMBERS'}), href: '/schoolmemberviewer', icon: UsersIcon, current: false, type:'ACCESS_SCHOOLMEMBERS'},
    { name: intl.formatMessage({id: 'training_info/BOT_DEPLOYMENT'}), href: '/botdeployment', icon: CubeTransparentIcon, current: false, type:'ACCESS_MODIFY_BOT'},
    { name: intl.formatMessage({id: 'training_info/BOT_TRAINING'}), href: '/training_info', icon: CubeTransparentIcon, current: false, type:'ACCESS_TRAINING_DATA'},
    { name: intl.formatMessage({id: 'training_info/SCHOOLWORK_AI'}), href: '/schoolworkmanager', icon: BookOpenIcon, current: false, type:'ACCESS_SCHOOLWORK_AI'},
    { name: intl.formatMessage({id: 'training_info/MC_GEN'}), href: '/questioneditor', icon: BookOpenIcon, current: false, type:'ACCESS_MC_GEN'},
    { name: intl.formatMessage({id: 'training_info/SHORT_Q_GEN'}), href: '/questionsetmaker', icon: BookOpenIcon, current: false, type:'ACCESS_SHORT_Q_GEN'},
    { name: intl.formatMessage({id: 'training_info/ESSAY_MARKER'}), href: '/essaymarker', icon: BookOpenIcon, current: false, type:'ACCESS_ESSAY_MARKER'},
    { name: intl.formatMessage({id: 'training_info/DISTRIBUTION_MANAGER'}), href: '/distributionmanger', icon: BookOpenIcon, current: false, type:'ACCESS_DISTRIBUTION_MANAGER'},
    { name: intl.formatMessage({id: 'training_info/DISTRIBUTION_FCMANAGER'}), href: '/flashcarddistribution', icon: BookOpenIcon, current: false, type:'ACCESS_DISTRIBUTION_FCMANAGER'},
    { name: intl.formatMessage({id: 'training_info/MYASSIGNMENT'}), href: '/myassignments', icon: BookOpenIcon, current: false, type:'ACCESS_MYASSIGNMENT'},
    { name: intl.formatMessage({id: 'training_info/SPEAKINGMANAGER'}), href: '/speakingManager', icon: BookOpenIcon, current: false, type:'ACCESS_DSEGROUPDISCUSSION'},

  ];
  return navigationArray;
}
export function readCookie(name) {
  var nameset = name + '=';
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameset) == 0) return c.substring(nameset.length,c.length);
  }
  return null;
}
export function getmetaboomurl(channelno) {
    console.log("calling getmetaboomurl():"+channelno);
        var seconds = Math.floor(new Date().getTime() / 1000) - 10;
        if (channelno >= 0) {
            var metaboomstring = channelno + "METABOOMXLINKED3" + seconds + 2;
            var hash = md5(metaboomstring);

            var urlrequest = "https://api.fansi.me/ALBUM/" + channelno + "/" + hash + "?expire=" + seconds + "&partner=2";
            console.log("metaboom urlrequest:"+urlrequest);
            return urlrequest;
        }
}
export function makerand(n) {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < n; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * 
        charactersLength));
      }
     return result;
  }
  
function md5(inputString) {
    var hc="0123456789abcdef";
    function rh(n) {var j,s="";for(j=0;j<=3;j++) s+=hc.charAt((n>>(j*8+4))&0x0F)+hc.charAt((n>>(j*8))&0x0F);return s;}
    function ad(x,y) {var l=(x&0xFFFF)+(y&0xFFFF);var m=(x>>16)+(y>>16)+(l>>16);return (m<<16)|(l&0xFFFF);}
    function rl(n,c)            {return (n<<c)|(n>>>(32-c));}
    function cm(q,a,b,x,s,t)    {return ad(rl(ad(ad(a,q),ad(x,t)),s),b);}
    function ff(a,b,c,d,x,s,t)  {return cm((b&c)|((~b)&d),a,b,x,s,t);}
    function gg(a,b,c,d,x,s,t)  {return cm((b&d)|(c&(~d)),a,b,x,s,t);}
    function hh(a,b,c,d,x,s,t)  {return cm(b^c^d,a,b,x,s,t);}
    function ii(a,b,c,d,x,s,t)  {return cm(c^(b|(~d)),a,b,x,s,t);}
    function sb(x) {
        var i;var nblk=((x.length+8)>>6)+1;var blks=new Array(nblk*16);for(i=0;i<nblk*16;i++) blks[i]=0;
        for(i=0;i<x.length;i++) blks[i>>2]|=x.charCodeAt(i)<<((i%4)*8);
        blks[i>>2]|=0x80<<((i%4)*8);blks[nblk*16-2]=x.length*8;return blks;
    }
    var i,x=sb(inputString),a=1732584193,b=-271733879,c=-1732584194,d=271733878,olda,oldb,oldc,oldd;
    for(i=0;i<x.length;i+=16) {olda=a;oldb=b;oldc=c;oldd=d;
        a=ff(a,b,c,d,x[i+ 0], 7, -680876936);d=ff(d,a,b,c,x[i+ 1],12, -389564586);c=ff(c,d,a,b,x[i+ 2],17,  606105819);
        b=ff(b,c,d,a,x[i+ 3],22,-1044525330);a=ff(a,b,c,d,x[i+ 4], 7, -176418897);d=ff(d,a,b,c,x[i+ 5],12, 1200080426);
        c=ff(c,d,a,b,x[i+ 6],17,-1473231341);b=ff(b,c,d,a,x[i+ 7],22,  -45705983);a=ff(a,b,c,d,x[i+ 8], 7, 1770035416);
        d=ff(d,a,b,c,x[i+ 9],12,-1958414417);c=ff(c,d,a,b,x[i+10],17,     -42063);b=ff(b,c,d,a,x[i+11],22,-1990404162);
        a=ff(a,b,c,d,x[i+12], 7, 1804603682);d=ff(d,a,b,c,x[i+13],12,  -40341101);c=ff(c,d,a,b,x[i+14],17,-1502002290);
        b=ff(b,c,d,a,x[i+15],22, 1236535329);a=gg(a,b,c,d,x[i+ 1], 5, -165796510);d=gg(d,a,b,c,x[i+ 6], 9,-1069501632);
        c=gg(c,d,a,b,x[i+11],14,  643717713);b=gg(b,c,d,a,x[i+ 0],20, -373897302);a=gg(a,b,c,d,x[i+ 5], 5, -701558691);
        d=gg(d,a,b,c,x[i+10], 9,   38016083);c=gg(c,d,a,b,x[i+15],14, -660478335);b=gg(b,c,d,a,x[i+ 4],20, -405537848);
        a=gg(a,b,c,d,x[i+ 9], 5,  568446438);d=gg(d,a,b,c,x[i+14], 9,-1019803690);c=gg(c,d,a,b,x[i+ 3],14, -187363961);
        b=gg(b,c,d,a,x[i+ 8],20, 1163531501);a=gg(a,b,c,d,x[i+13], 5,-1444681467);d=gg(d,a,b,c,x[i+ 2], 9,  -51403784);
        c=gg(c,d,a,b,x[i+ 7],14, 1735328473);b=gg(b,c,d,a,x[i+12],20,-1926607734);a=hh(a,b,c,d,x[i+ 5], 4,    -378558);
        d=hh(d,a,b,c,x[i+ 8],11,-2022574463);c=hh(c,d,a,b,x[i+11],16, 1839030562);b=hh(b,c,d,a,x[i+14],23,  -35309556);
        a=hh(a,b,c,d,x[i+ 1], 4,-1530992060);d=hh(d,a,b,c,x[i+ 4],11, 1272893353);c=hh(c,d,a,b,x[i+ 7],16, -155497632);
        b=hh(b,c,d,a,x[i+10],23,-1094730640);a=hh(a,b,c,d,x[i+13], 4,  681279174);d=hh(d,a,b,c,x[i+ 0],11, -358537222);
        c=hh(c,d,a,b,x[i+ 3],16, -722521979);b=hh(b,c,d,a,x[i+ 6],23,   76029189);a=hh(a,b,c,d,x[i+ 9], 4, -640364487);
        d=hh(d,a,b,c,x[i+12],11, -421815835);c=hh(c,d,a,b,x[i+15],16,  530742520);b=hh(b,c,d,a,x[i+ 2],23, -995338651);
        a=ii(a,b,c,d,x[i+ 0], 6, -198630844);d=ii(d,a,b,c,x[i+ 7],10, 1126891415);c=ii(c,d,a,b,x[i+14],15,-1416354905);
        b=ii(b,c,d,a,x[i+ 5],21,  -57434055);a=ii(a,b,c,d,x[i+12], 6, 1700485571);d=ii(d,a,b,c,x[i+ 3],10,-1894986606);
        c=ii(c,d,a,b,x[i+10],15,   -1051523);b=ii(b,c,d,a,x[i+ 1],21,-2054922799);a=ii(a,b,c,d,x[i+ 8], 6, 1873313359);
        d=ii(d,a,b,c,x[i+15],10,  -30611744);c=ii(c,d,a,b,x[i+ 6],15,-1560198380);b=ii(b,c,d,a,x[i+13],21, 1309151649);
        a=ii(a,b,c,d,x[i+ 4], 6, -145523070);d=ii(d,a,b,c,x[i+11],10,-1120210379);c=ii(c,d,a,b,x[i+ 2],15,  718787259);
        b=ii(b,c,d,a,x[i+ 9],21, -343485551);a=ad(a,olda);b=ad(b,oldb);c=ad(c,oldc);d=ad(d,oldd);
    }
    return rh(a)+rh(b)+rh(c)+rh(d);
}
export function extractJsonFromString(inputString) {

  const startIndex = inputString.indexOf('{');
  const endIndex = inputString.lastIndexOf('}');

  if (startIndex !== -1 && endIndex !== -1 && startIndex < endIndex) {
      const substring = inputString.slice(startIndex, endIndex + 1);
      return "["+substring+"]";
  }

  return inputString;
  
}
export function escapeJsonString(str) {
  return str.replace(/[\\"\u0000-\u001F\u007F-\u009F\u00AD\u0600-\u0604\u070F\u17B4\u17B5\u200C-\u200F\u2028-\u202F\u2060-\u206F\uFEFF\uFFF0-\uFFFF]/g, function(c) {
    switch (c) {
      case '"':
      case '\\':
      case '/':
        return '\\' + c;
      case '\b':
        return '\\b';
      case '\f':
        return '\\f';
      case '\n':
        return '\\n';
      case '\r':
        return '\\r';
      case '\t':
        return '\\t';
      default:
        return '\\u' + ('0000' + c.charCodeAt(0).toString(16)).slice(-4);
    }
  });
}
export function convertISODateTimeToFormattedString(isoDateTime) {
  const date = new Date(isoDateTime);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const formattedString = `${year}-${month}-${day} ${hours}:${minutes}`;
  return formattedString;
}
export var callOpenSeaFetchWalletAssets = async (wallet_address) => {
  // alert("App.js Calling /openSea_fetch_wallet_assets: " + wallet_address);
  // console.log("App.js Calling /openSea_fetch_wallet_assets: " + wallet_address);
  // alert("calling callOpenSeaFetchWalletAssets");
  var params = new URLSearchParams({
    addr: wallet_address,
    color2: 'blue',
  });

  const response = await fetch('/openSea_fetch_wallet_assets?' + params);
  const body = await response.json();

  if (response.status !== 200) {
    throw Error(body.message) 
  }
  // console.log(body.assets.length);
  var nft_count = 0;

  // alert("mypage.js: callOpenSeaFetchWalletAssets" + body.assets.length);
  console.log("callOpenSeaFetchWalletAssets ETH body.result: _______________" );
  console.log(body.assets);
  //traits
  /*
  [
    {
        "trait_type": "bgc",
        "value": "#000000",
        "display_type": null,
        "max_value": null,
        "trait_count": 0,
        "order": null
    },
    {
        "trait_type": "btnborder1",
        "value": "1px solid #ffffff",
        "display_type": null,
        "max_value": null,
        "trait_count": 0,
        "order": null
    }
]
  */

  return body.assets;

}  


export var callMoralisFetchWalletAssets_eth = async (wallet_address) => {

  var params = new URLSearchParams({
    addr: wallet_address,
    color2: 'blue',
  });

  const response = await fetch('/moralis_fetch_wallet_assets_eth?' + params);
  const body = await response.json();

  if (response.status !== 200) {
    throw Error(body.message) 
  }
  // console.log(body.result.length + "________");
  
  // fetch all nft in body.assets  
  console.log("callMoralisFetchWalletAssets ETH body.result: _______________" );

  // if (body.result.length == 0) {
  //   alert("heyyyy 000");
  //   return;
  // }
  // console.log("callMoralisFetchWalletAssets ETH body.result.length: " + body.result.length);
  // console.log("address: " + wallet_address);
  // alert("callMoralisFetchWalletAssets ETH body.result.length: " + body.result.length);
  console.log(body.result);

 
  return body.result;


  // alert("callMoralisFetchWalletAssets ETH: " + body.result[0]);

  // if (body.records == undefined) {
  //   return body;
  // }
  // var nft_metadata_json = body.result[0].metadata;
  // var nft_metadata_obj = JSON.parse(nft_metadata_json);
  // console.log(nft_metadata_obj);

};

export function removeHTMLTagsExceptSubSup(str) {
  //str = str.replace(/<\/p>/g, '</p>\n');
  str = str.replace(/<\/p>/g, '</p></br>');

  str = str.replace("<em>","");
  str = str.replace("</em>","");
  return str.replace(/<(?!\/br|sub|\/sub|sup|\/sup)[^>]+>/g, '');
}
export function removeHTMLTagsExceptSubSupForTextArea(str) {
  //str = str.replace(/<\/p>/g, '</p>\n');
  str = str.replace(/<\/p>/g, '</p></br>');

  str = str.replace("<em>","");
  str = str.replace("</em>","");
  return str.replace(/<(?!\/br|sub|\/sub|sup|\/sup)[^>]+>/g, '');
}
export var callMoralisFetchWalletAssets_polygon = async (wallet_address) => {

  var params = new URLSearchParams({
    addr: wallet_address,
    color2: 'blue',
  });

  const response = await fetch('/moralis_fetch_wallet_assets_polygon?' + params);
  const body = await response.json();

  if (response.status !== 200) {
    throw Error(body.message) 
  }
  // console.log(body.result.length + "________");
  
  // fetch all nft in body.assets  
  console.log("callMoralisFetchWalletAssets Polygon body.result: ___________@@@@@@@@@@@@@@@@@@@@@" );
  console.log(body.result);

  return body.result;

  // if (body.result == undefined) {
  //   return;
  // }

  // try {
  //   var nft_metadata_json = body.result[0].metadata;
  //   var nft_metadata_obj = JSON.parse(nft_metadata_json);
  //   console.log(nft_metadata_obj);

  // } catch(error) {}



  // alert(body.result.length);
};

// TODO: can deprecate?
export function getMenuForAuthorization(accessArray,initialNavigation, intl)
{
  
  console.log("AccessArray");
  console.log(accessArray);
  console.log("initialNavigation");
  console.log(initialNavigation);
  
   const navigationArray = [
   { name: intl.formatMessage({id: 'training_info/DASHBOARD'}), href: '/portal', icon: HomeIcon, current: true },
   { name: intl.formatMessage({id: 'training_info/ADMIN'}), href: '/adminpanel', icon: UsersIcon, current: true, type:'ACCESS_MODIFY_USER' },
   { name: intl.formatMessage({id: 'training_info/MANAGESCHOOLMEMBERS'}), href: '/schoolmembermanager', icon: UsersIcon, current: false, type:'ACCESS_MANAGESCHOOLMEMBERS'},
   { name: intl.formatMessage({id: 'training_info/MANAGECLASSES'}), href: '/schoolclassviewer', icon: UsersIcon, current: false, type:'ACCESS_MANAGECLASSES'},
   { name: intl.formatMessage({id: 'training_info/SCHOOLMEMBERS'}), href: '/schoolmemberviewer', icon: UsersIcon, current: false, type:'ACCESS_SCHOOLMEMBERS'},
   { name: intl.formatMessage({id: 'training_info/BOT_DEPLOYMENT'}), href: '/botdeployment', icon: CubeTransparentIcon, current: false, type:'ACCESS_MODIFY_BOT'},
   { name: intl.formatMessage({id: 'training_info/BOT_TRAINING'}), href: '/training_info', icon: CubeTransparentIcon, current: false, type:'ACCESS_TRAINING_DATA'},
   { name: intl.formatMessage({id: 'training_info/SCHOOLWORK_AI'}), href: '/schoolworkmanager', icon: BookOpenIcon, current: false, type:'ACCESS_SCHOOLWORK_AI'},
   { name: intl.formatMessage({id: 'training_info/SCHOOLWORK_MC'}), href: '/mcmanager', icon: BookOpenIcon, current: false, type:'ACCESS_MULTIPLECHOICE_MANAGER'},
   { name: intl.formatMessage({id: 'training_info/SCHOOLWORK_FC'}), href: '/flashcardmanager', icon: BookOpenIcon, current: false, type:'ACCESS_FLASHCARD_MANAGER'},
   { name: intl.formatMessage({id: 'training_info/MC_GEN'}), href: '/questioneditor', icon: BookOpenIcon, current: false, type:'ACCESS_MC_GEN'},
   { name: intl.formatMessage({id: 'training_info/SHORT_Q_GEN'}), href: '/questionsetmaker', icon: BookOpenIcon, current: false, type:'ACCESS_SHORT_Q_GEN'},
   { name: intl.formatMessage({id: 'training_info/ESSAY_MARKER'}), href: '/essaymarker', icon: BookOpenIcon, current: false, type:'ACCESS_ESSAY_MARKER'},
   { name: intl.formatMessage({id: 'training_info/DISTRIBUTION_MANAGER'}), href: '/distributionmanger', icon: BookOpenIcon, current: false, type:'ACCESS_DISTRIBUTION_MANAGER'},
   { name: intl.formatMessage({id: 'training_info/DISTRIBUTION_FCMANAGER'}), href: '/flashcarddistribution', icon: BookOpenIcon, current: false, type:'ACCESS_DISTRIBUTION_FCMANAGER'},
   { name: intl.formatMessage({id: 'training_info/MYASSIGNMENT'}), href: '/myassignments', icon: BookOpenIcon, current: false, type:'ACCESS_MYASSIGNMENT'},
   { name: intl.formatMessage({id: 'training_info/SPEAKINGMANAGER'}), href: '/speakingManager', icon: BookOpenIcon, current: false, type:'ACCESS_DSEGROUPDISCUSSION'},
   { name: intl.formatMessage({id: 'training_info/DISTRIBUTION_MANAGER'}), href: '/markdistribution', icon: BookOpenIcon, current: false, type:'ACCESS_MARKER'}
   ];
  if(accessArray===null)
  {
    return [];
  }
  else if(accessArray.includes("ACCESS_TLC_DEMO"))
  {
    console.log("ACCESS_TLC_DEMO detected");
    var updatedNavigation = [
      initialNavigation[7], initialNavigation[8], initialNavigation[9]
  
    ];
    
    return updatedNavigation;
  }
  else if(accessArray.includes("ACCESS_ALL_DEV"))
  {
    console.log("ACCESS_ALL_DEV");
    return initialNavigation;
  }
  else if (accessArray != undefined) {
  
    function filterNavigation(accessArray) {
      return navigationArray.filter(item => accessArray.includes(item.type));
    }
    return filterNavigation(accessArray);
  
    // If not include ACCESS_MODIFY_USER, then remove admin option
    // var updatedNavigation = initialNavigation;
    // if (!accessArray.includes("ACCESS_MODIFY_USER")) {
    //   updatedNavigation = updatedNavigation.filter(function (item) {
    //     return item.type !== 'ACCESS_MODIFY_USER';
    //   });
    // }

    // // If not include ACCESS_MODIFY_BOT, then remove bot deployment option
    // if (!accessArray.includes("ACCESS_MODIFY_BOT")) {
    //   updatedNavigation = updatedNavigation.filter(function (item) {
    //     return item.type !== 'ACCESS_MODIFY_BOT';
    //   });
    // }

    // // If not include ACCESS_MODIFY_BOT, then remove bot deployment option
    // if (!accessArray.includes("ACCESS_TRAINING_DATA")) {
    //   updatedNavigation = updatedNavigation.filter(function (item) {
    //     return item.type !== 'ACCESS_TRAINING_DATA';
    //   });
    // }

    // // If not include ACCESS_MODIFY_BOT, then remove bot deployment option
    // if (!accessArray.includes("ACCESS_BOT")) {
    //   updatedNavigation = updatedNavigation.filter(function (item) {
    //     return item.type !== 'ACCESS_BOT';
    //   });
    // }

    // Default option: 
    //setNavigation(updatedNavigation);
  }
}

export function genid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * 
 charactersLength));
    }
   return result;
}

// strip html tag, prevent injection attack
export function strip(html){
   let doc = new DOMParser().parseFromString(html, 'text/html');
   return doc.body.textContent || "";
}

export function copytoclipboard(string) {
  var intermediatetext;
  var stringtocopy;

  try {
    intermediatetext = document.createElement('textarea');
    intermediatetext.setAttribute('readonly', true);
    intermediatetext.setAttribute('contenteditable', true);
    intermediatetext.style.position = 'fixed'; 
    intermediatetext.value = string;

    document.body.appendChild(intermediatetext);

    intermediatetext.focus();
    intermediatetext.select();

    const range = document.createRange();
    range.selectNodeContents(intermediatetext);

    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);

    intermediatetext.setSelectionRange(0, intermediatetext.value.length);
    stringtocopy = document.execCommand('copy');
  } catch (err) {
    console.error(err);
    stringtocopy = null;
  } finally {
    document.body.removeChild(intermediatetext);
  }

  if (!stringtocopy) {
    const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    const copyHotkey = isMac ? '⌘C' : 'CTRL+C';
    stringtocopy = prompt(`Press ${copyHotkey}`, string); 
    if (!stringtocopy) {
      return false;
    }
  }
  return true;
}

// export function mintNFT() {
//   var nft_src = [
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_10-eye18-eyesspot2-mouth12-nose1-facespot3-bg17-pattern11-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_5-eye14-eyesspot1-mouth9-nose1-facespot5-bg1-pattern11-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_5-eye12-eyesspot1-mouth11-nose3-facespot2-bg17-pattern11-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_7-eye2-eyesspot2-mouth4-nose2-facespot2-bg8-pattern11-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_7-eye15-eyesspot2-mouth8-nose3-facespot2-bg14-pattern11-hat24.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_11-eye20-eyesspot2-mouth11-nose1-facespot2-bg4-pattern11-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_12-eye2-eyesspot1-mouth12-nose1-facespot5-bg4-pattern11-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_7-eye7-eyesspot2-mouth10-nose2-facespot3-bg12-pattern11-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_3-eye25-eyesspot2-mouth8-nose3-facespot2-bg1-pattern11-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_6-eye16-eyesspot1-mouth2-nose3-facespot5-bg12-pattern11-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_8-eye21-eyesspot2-mouth14-nose1-facespot2-bg14-pattern11-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_6-eye18-eyesspot2-mouth9-nose3-facespot5-bg5-pattern11-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_12-eye22-eyesspot1-mouth7-nose1-facespot3-bg13-pattern11-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_6-eye1-eyesspot1-mouth14-nose2-facespot2-bg13-pattern11-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_5-eye23-eyesspot1-mouth5-nose1-facespot5-bg5-pattern11-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_7-eye22-eyesspot1-mouth12-nose2-facespot5-bg14-pattern11-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_10-eye3-eyesspot1-mouth5-nose2-facespot5-bg1-pattern11-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_1-eye7-eyesspot1-mouth5-nose2-facespot2-bg2-pattern11-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_5-eye10-eyesspot2-mouth7-nose3-facespot2-bg10-pattern11-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_2-eye10-eyesspot2-mouth10-nose3-facespot5-bg16-pattern11-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_1-eye4-eyesspot2-mouth12-nose3-facespot5-bg4-pattern11-hat37.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_1-eye20-eyesspot2-mouth10-nose1-facespot2-bg15-pattern11-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_3-eye24-eyesspot1-mouth5-nose1-facespot2-bg8-pattern11-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_7-eye9-eyesspot1-mouth10-nose1-facespot2-bg3-pattern11-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_1-eye1-eyesspot2-mouth11-nose1-facespot2-bg1-pattern11-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_8-eye23-eyesspot1-mouth2-nose3-facespot2-bg9-pattern11-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_10-eye24-eyesspot2-mouth14-nose3-facespot5-bg17-pattern11-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_8-eye6-eyesspot1-mouth5-nose1-facespot4-bg16-pattern11-hat27.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_12-eye9-eyesspot2-mouth2-nose3-facespot3-bg14-pattern11-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_1-eye2-eyesspot2-mouth5-nose3-facespot3-bg10-pattern11-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_10-eye2-eyesspot1-mouth5-nose2-facespot3-bg6-pattern11-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_1-eye1-eyesspot1-mouth11-nose2-facespot2-bg5-pattern10-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_12-eye2-eyesspot2-mouth8-nose3-facespot2-bg8-pattern11-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_8-eye12-eyesspot1-mouth4-nose1-facespot5-bg5-pattern11-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_10-eye1-eyesspot1-mouth7-nose1-facespot4-bg17-pattern11-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_12-eye19-eyesspot2-mouth8-nose3-facespot5-bg7-pattern11-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_2-eye14-eyesspot2-mouth9-nose3-facespot4-bg17-pattern11-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_5-eye11-eyesspot1-mouth8-nose2-facespot2-bg11-pattern11-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_1-eye3-eyesspot1-mouth4-nose3-facespot5-bg7-pattern11-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_6-eye18-eyesspot1-mouth1-nose3-facespot2-bg7-pattern11-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_12-eye11-eyesspot1-mouth14-nose2-facespot4-bg13-pattern11-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_11-eye6-eyesspot2-mouth8-nose3-facespot2-bg17-pattern11-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_1-eye2-eyesspot1-mouth9-nose1-facespot2-bg5-pattern11-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_6-eye15-eyesspot1-mouth5-nose2-facespot2-bg2-pattern11-hat29.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_11-eye15-eyesspot2-mouth13-nose1-facespot2-bg3-pattern11-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_12-eye18-eyesspot1-mouth11-nose1-facespot2-bg2-pattern11-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_12-eye22-eyesspot1-mouth1-nose1-facespot2-bg12-pattern11-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_2-eye21-eyesspot2-mouth10-nose2-facespot2-bg7-pattern11-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_10-eye14-eyesspot2-mouth10-nose2-facespot4-bg14-pattern11-hat67.png",
//   "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_5-eye22-eyesspot2-mouth11-nose3-facespot2-bg16-pattern11-hat67.png"
//   ];


//   var nft_attributes = [];

//   var nft_single_attribute = {};

//   nft_single_attribute["trait_type"] = "Tall";
//   nft_single_attribute["value"] = "203";

//   nft_attributes.push(nft_single_attribute);



//   nft_single_attribute["trait_type"] = "Eyes";
//   nft_single_attribute["value"] = "small";

//   nft_attributes.push(nft_single_attribute);



//   nft_single_attribute["trait_type"] = "Mouth";
//   nft_single_attribute["value"] = "kiss";

//   nft_attributes.push(nft_single_attribute);



//   // nft_attributes.push(
//   //   {
//   //     "trait_type": "Tall", 
//   //     "value": "203"
//   //   }
//   // );

//   // nft_attributes.push(
//   //   {
//   //     "trait_type": "Eyes", 
//   //     "value": "small"
//   //   }
//   // );


//   // nft_attributes.push(
//   //   {
//   //     "trait_type": "Mouth", 
//   //     "value": "kiss"
//   //   }
//   // );

//   var nft_json = {};
//   nft_json["description"] = "CockyShiba NFT";
//   nft_json["image"] = "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_10-eye18-eyesspot2-mouth12-nose1-facespot3-bg17-pattern11-hat67.png";
//   nft_json["name"] = "#0001_autominted";
//   nft_json["attributes"] = nft_attributes;


//   console.log("____________________________________");
//   var str = JSON.stringify(nft_json, null, 2);
//   console.log(str);

//   alert(str);

//   // {
//   // "description": "CockyShiba NFT", 
//   // "image": "http://www.cockyshiba.com/smartcontract/neon/res/shibaNFT/body_8-eye6-eyesspot1-mouth5-nose1-facespot4-bg16-pattern11-hat27.png", 
//   // "name": "#0001_autominted",
//   // "attributes": [
//   //   {
//   //     "trait_type": "Tall", 
//   //     "value": "203"
//   //   }, 
//   //   {
//   //     "trait_type": "Eyes", 
//   //     "value": "small"
//   //   }, 
//   //   {
//   //     "trait_type": "Mouth", 
//   //     "value": "kiss"
//   //   }
//   //   ]
//   // }
    
    
//   return null;
// }